/*
 * @Author: kok-s0s
 * @Date: 2021-08-17 15:54:41
 * @LastEditors: kok-s0s
 * @LastEditTime: 2021-08-17 16:22:47
 * @Description: file content
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./Drum.js";
import "./drum-style.css";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
